import React from 'react';
import { Link } from 'gatsby';

import { BrandingSVG, DigitalMarketingSVG, DesignSVG, DevelopingSVG, SeoSVG } from '../components/svgs'

const ServicesOverview = () => {

    return(
        <div className="bg-white w-full pt-10 pb-12">

        <h2 className="text-center">What can Rare do for you?</h2>
        <p className="text-center text-xs font-semibold mt-4">Competitive organizations need contemporary digital solutions.</p>
        <p className="text-center text-xs font-semibold">Learn more about the services we can offer your business.</p>
  
        <div className="flex flex-wrap mt-6 text-sm">
            <div className="w-1/2 laptop:w-1/5 laptop:border-r-2">
                <div style={{ height: '120px' }} className="flex items-center justify-around">
                    <BrandingSVG className="h-14" />
                </div>
                <p className="text-center font-semibold">Branding</p>
            </div>
            <div className="w-1/2 laptop:w-1/5 laptop:border-r-2">
                <div style={{ height: '120px' }} className="flex items-center justify-around">
                    <DesignSVG className="h-8" />
                </div>
                <p className="text-center font-semibold">Mobile Friendly</p>
                <p className="text-center font-semibold">Responsive Design</p>
            </div>
            <div className="w-1/2 laptop:w-1/5 laptop:border-r-2">
                <div style={{ height: '120px' }} className="flex items-center justify-around">
                    <DevelopingSVG className="h-8" />
                </div>
                <p className="text-center font-semibold">Custom Web</p>
                <p className="text-center font-semibold">Development</p>
            </div>
            <div className="w-1/2 laptop:w-1/5 laptop:border-r-2">
                <div style={{ height: '120px' }} className="flex items-center justify-around">
                    <SeoSVG className="h-8" />
                </div>
                <p className="text-center font-semibold">Search Engine</p>
                <p className="text-center font-semibold">Optimization</p>
            </div>
            <div className="w-full laptop:w-1/5">
                <div style={{ height: '120px' }} className="flex items-center justify-around">
                    <DigitalMarketingSVG className="h-12" />
                </div>
                <p className="text-center font-semibold">Digital Marketing</p>
            </div>
            <Link
                to="/services/"
                className="w-2/3 mx-auto rounded-md text-white text-center font-semibold bg-magenta mt-12 py-2"
                style={{ maxWidth: '512px' }}
            >
                Learn about our services
            </Link>
        </div>
  
      </div>
    )
}

export default ServicesOverview;