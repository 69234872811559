import React from 'react'
import Image from './image-provider'
import Slider from 'react-slick'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from '../css/image-slider.module.css'

import Introduction from '../graphics/gladyoufoundus.svg'
import Grow from '../graphics/helpgrowsmallbusiness.svg'
import Expand from '../graphics/expandonlinepresence2.svg'

const ImageSlider = () => {

    const settings = {
        className: styles.imageSlider,
        arrows: false,
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnHover: false
    };

    return(
        <Slider {...settings}>
            <div className="relative">
                <Image
                    fileName="urbanalleyway.jpg"
                    className="h-screen min-height"
                    alt="Urban alleyway with vibrantly colored brick walls"
                />
                <div className="absolute top-0 left-0 h-screen min-height w-full flex items-center justify-around bg-black bg-opacity-20">
                    <img
                        src={Introduction}
                        alt="Glad you found us, We're Rare."
                        className="w-2/3"
                        style={{ maxWidth: '512px' }}/>
                </div>
            </div>
            <div className="relative">
                <Image
                    fileName="busycafe.jpg"
                    className="h-screen min-height"
                    alt="Overhead view of a cashier handling a busy queue in a coffee shop"
                />
                <div className="absolute top-0 left-0 h-screen min-height w-full flex items-center justify-around bg-black bg-opacity-20">
                    <img
                        src={Grow}
                        alt="We help small businesses grow"
                        className="w-2/3"
                        style={{ maxWidth: '512px' }}/>
                </div>
            </div>
            <div className="relative">
                <Image
                    fileName="computerworkspace.jpg"
                    className="h-screen min-height"
                    alt="Top-down view of a large desk filled with computers, work materials, a tea pot, and more"
                />
                <div className="absolute top-0 left-0 h-screen min-height w-full flex items-center justify-around bg-black bg-opacity-20">
                    <img 
                        src={Expand}
                        alt="Allow us to expand your online presence"
                        className="w-11/12"
                        style={{ maxWidth: '704px' }} />
                </div>
            </div>
        </Slider>
    )
}

export default ImageSlider;