import React from "react"
import Image from '../components/image-provider'
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageSlider from '../components/image-slider'
import ServicesOverview from '../components/services-overview'

import { ArrowRightSVG } from '../components/svgs'
import OfficeAnime from '../graphics/rarechicago-officeanime.svg'
import Mountains from '../images/hunanmountains.webp'

const IndexPage = ({ data, location }) => {

    const schema = 
        {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Rare Chicago",
            "description": "Chicago based Web Development and Digital Marketing Agency.",
            "@id": "https://rarechicago.com/#business",
            "url": "https://rarechicago.com",
            "logo": "https://rarechicago.com/schema_images/logo.png",
            "image": "https://rarechicago.com/schema_images/image.jpg",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "",
                "addressLocality": "Chicago",
                "addressRegion": "IL",
                "postalCode": "60614",
                "addressCountry": "US"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "3129998388",
                "contactType": "customer service",
                "areaServed": "US",
                "availableLanguage": ["en","Chinese (Simplified)"]
            },
            "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
                ],
                "opens": "09:00",
                "closes": "17:00"
            },
            "telephone": "3129998388",
            "email": "hello@rarechicago.com",
            "areaServed": [{
                "@type": "City",
                "name": ["Chicago"]
            }],
            "sameAs": [
                "https://www.facebook.com/rarechicagotech",
                "https://twitter.com/rarechicagotech",
                "https://www.instagram.com/rarechicagotech",
                "https://www.youtube.com/channel/UCjJjq6DX46hkQYqUx3ou0bA?fbclid=IwAR376_3na_j94bes0844YNSHiofU2b-1uTY-W3z8jiXfMx0gnb_2SoEkH1g",
                "https://www.linkedin.com/company/rarechicago",
                "https://github.com/rarechicago",
                "https://rarechicago.com"
            ]
        }

    

    const socialCardImage = data.allFile.edges[0].node.childImageSharp.resize

    return(
        <Layout>
            <SEO
                title="Home"
                image={socialCardImage}
                pathname={location.pathname}
                schema={schema}
            />
            <ImageSlider />
                <div className="max-viewport mx-auto">
                    <ServicesOverview />
                </div>

                <div className="bg-fixed" style={{ backgroundImage: `url(${Mountains})` }}>
                    <div className="max-viewport mx-auto p-10 laptop:p-36">
                        <div className="bg-white bg-opacity-80 p-8 rounded-md">                     
                            <img
                                src={OfficeAnime} 
                                className="w-5/6 mx-auto"
                                alt="Cartoon graphic of two people working at computer desks. Their ideads are connected visually in a metaphorical `cloud` above."
                                style={{ maxWidth: '420px' }}
                            />
                            <p className="my-8 text-center laptop:px-12 desktop:px-28">We are a small team of people dedicated to our craft. Technology is an integral part of our lives. We live to build meaningful user experiences.</p>
                            <Link
                                to="/about/"
                                className="flex items-center mx-auto hover:pointer"
                                style={{ width: '176px' }}
                            >
                                <p className="text-matteblue mr-1">Learn more about us</p>
                                <ArrowRightSVG className="fill-current opacity-50" />
                            </Link>
                        </div>          
                    </div>
                </div>

                <div className="bg-dkpurple w-full">
                    <div className="max-viewport mx-auto py-16">
                        <div className="w-full flex flex-wrap text-white text-center">
                            <h2 className="w-full text-xl">We have what you're looking for.</h2>
                            <div className="w-full px-4 mt-12 mb-16">
                                <p className="mx-auto" style={{ maxWidth:'992px' }}>From custom web design, cloud hosting, and ecommerce integration, to multimedia, graphic design, and digital marketing solutions, we’ve got you covered. Every business requires a unique strategy to fulfill their goals. What are your goals?</p>
                            </div>
                            <Link
                                to="/contact/"
                                className="w-2/3 mx-auto bg-ltblue rounded-md py-2 text-white font-semibold text-sm"
                                style={{ maxWidth: '512px' }}
                            >
                                Contact us
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <Image
                        fileName="chicagobridge.jpg"
                        alt="Photograph taken in the center of a busy bridge in Chicago's Loop."
                        style={{ height: '640px' }}
                    />

                    <div className="absolute top-0 left-0 w-full">

                        <div className="max-viewport mx-auto flex items-center px-8 laptop:px-12" style={{ height: '640px' }}>

                            <div className="bg-white bg-opacity-80 p-8 rounded-md flex flex-wrap">
                                <div className="w-full text-center">
                                    <p className="mb-8">Our goal is to make a positive impact on people's lives and to bring ideas and concepts to life.  Our passion is reflected in the way we work together, the projects we take on, and in our work itself.</p>
                                    <Link
                                        to="/about/"
                                        className="flex items-center mx-auto hover:pointer"
                                        style={{ width: '176px' }}
                                    >
                                        <p className="text-matteblue mr-1">Learn more about us</p>
                                        <ArrowRightSVG className="fill-current opacity-50" />
                                    </Link>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

        </Layout>
    )
}

export default IndexPage;

export const pageQuery = graphql`
    query {
        allFile(filter: {name: {eq: "rarechicago-socialcard-home"}}) {
        edges {
            node {
            childImageSharp {
                resize(width: 1200) {
                src
                height
                width
                }
            }
            }
        }
        }
    }
`
